/**
 * Forms
 */

@input-border: fade(black,10%);
@input-border-focus: fade(black,15%);

.form-control,
.form-control:focus {
  box-shadow: none;
  outline: none;
}


/* Has error */

.has-error {

  .form-control {
    border-color: @brand-danger;
    box-shadow: none !important;

    &:focus {
      border-color: darken(@brand-danger,15%);
    }
  }
  .help-block {
    color: @brand-danger;
  }
}


/* Checkboxes */

.checkbox {
  
  input[type="checkbox"] {
    display: none;
  }
  label {
    padding-left: 0;
    
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
      width: 20px; height: 20px;
      line-height: 20px;
      background-color: #eee;
      text-align: center;
      font-family: "FontAwesome";
    }
  }
  input[type="checkbox"]:checked + label::before {
    content: "\f00c";
  }

}


/* Radios */

.radio {

  input[type="radio"] {
    display: none;
  }
  label {
    padding-left: 0;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
      width: 20px; height: 20px;
      border-radius: 50%;
      border: 10px solid #eee;
      background-color: @text-color; 
    }
  }
  input[type="radio"]:checked + label:before {
    border-width: 7px;
  }
}


/* Quantity */

.input_qty {
  margin-bottom: 10px;

  input[type="text"] {
    display: none;
  }
  label {
    width: 100%; height: 40px;
    border: 1px solid fade(black,10%);
    line-height: 40px;
    text-align: center;

    > span:not(.output) {
      width: 40px; height: 40px;
      float: left;
      border-right: 1px solid fade(black,10%);
      cursor: pointer;
      -webkit-user-select: none;
              user-select: none;

      &:last-child {
        float: right;
        border-right: 0;
        border-left: 1px solid fade(black,10%);
      }
      &:hover {
        background-color: fade(black,2%);
      }
    }
    > output {
      display: inline-block;
      line-height: inherit;
      padding: 0;
    }
  }
}
.input_qty_sm {
  
  
  label {
    width: 80px; height: 20px;
    border: 0;
    line-height: 20px;
    color: #ccc;

    > span:not(.output) {
      width: 20px; height: 20px;
      border: 0 !important;

      &:hover {
        background-color: transparent;
        color: @text-color;
      }
    }
    output {
      color: #ccc;
      font-weight: 600;
    }
  }
}