/**
 * Info boards
 */

.info-board {
  margin-bottom: 20px;
  padding: 30px;
  background: #f9f9f9;
  border-top: 2px solid fade(black,20%);
}
.info-board__header {
  margin-bottom: 20px;
  font-size: (@font-size-base + 2px);
  font-weight: 600;
}
.info-board_primary {
  border-color: @brand-primary;
  background-color: fade(@brand-primary,5%);
}
.info-board_secondary {
  border-color: @brand-sec;
  background-color: fade(@brand-sec,5%);
}