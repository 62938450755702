/**
 * Index: version 3
 */

/* General */

.index-3__section {
  padding: 60px 0;
}
.index-3__section_inverse {
  background-color: fade(@brand-gray,2%);
}


/* Jumbotron */

.index-3__jumbotron {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%; height: 600px;
  margin-top: -@line-height-computed;
  background: @brand-gray url(../img/home_10.jpg) no-repeat center center / cover;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: fade(@brand-gray,90%);
  }
}
.index-3-jumbotron__inner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.index-3-jumbotron__heading {
  font-family: 'Questrial', sans-serif;
  font-size: 40px;
  color: white;
  margin-bottom: 30px;
  text-shadow: 1px 1px 1px fade(black,50%);
}
.index-3-jumbotron__subheading {
  font-size: (@font-size-base + 1px);
  color: fade(white,50%);
  margin-bottom: 60px;
  text-shadow: 1px 1px 1px fade(black,10%);
}
.index-3-jumbotron__btn {

  .btn-link {
    color: fade(white,50%);

    &:hover, &:focus {
      color: #fff;
    }
  }
}
@media(min-width: @screen-md-min) {
  .index-3__jumbotron {
    text-align: left;
  }
}


/* Headings */

.index-3__heading {
  font-family: 'Questrial', sans-serif;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 60px;
}
.index-3__subheading {
  font-size: (@font-size-base + 1px);
  color: @text-muted;
  margin-top: -50px;
  margin-bottom: 60px;
}


/* Features */

.index-3-features__item {
  margin-bottom: 20px;
}
.index-3-features__img,
.index-3-features__heading,
.index-3-features__subheading {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}
.index-3-features__icon {
  font-size: 24px;
}
.index-3-features__img {
  margin-bottom: 20px;
  border-radius: @border-radius-base;
  overflow: hidden;
}
.index-3-features__heading {
  font-family: 'Questrial', sans-serif;
  margin-bottom: 20px;
  line-height: 1.3;
}
.index-3-features__subheading {
  color: @text-muted;
  line-height: 1.7;
  margin-bottom: 10px;
}


/* Features 2 */

.index-3-features-2__item {
  margin-bottom: 30px;
}
.index-3-features-2__icon {
  @size: 40px;
  width: @size; height: @size;
  line-height: @size;
  text-align: center;
  border-radius: 3px;
  color: white;
  background-color: @brand-gray;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
}
.index-3-features-2__heading {
  display: inline-block;
  font-family: 'Questrial', sans-serif;
  font-size: 20px;
  line-height: 40px;
}
.index-3-features-2__content {
  color: @text-muted;
}


/* Subscribe */

.index-3__sign-up {
  
  .form-control {
    height: 44px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  [type="submit"] {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media(min-width: @screen-sm-min) {
  
  .index-3__sign-up {

    .row > [class*="col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}