/**
 * Shop
 */

/** Shop: Index **/

/* General */

.shop-index__section {
  position: relative;
  margin-bottom: 60px;

  &.alt {
    background-color: #f5f5f5;
    padding-top: 60px;
    padding-bottom: 60px;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: fade(black,5%);
  }
}
.shop-index__heading {
  margin-top: 0;
  margin-bottom: 60px;
  font-family: 'Questrial', sans-serif;

  + p {
    margin-top: -50px;
    margin-bottom: 60px;
    color: @text-muted;
  }
}

/* Shop: Slideshow */

.shop__slideshow {
  margin-top: -@line-height-computed;
  margin-bottom: 60px;

  .carousel-inner {

    .item__container {
      display: table;
      width: 100%; height: 100%;
    }
    .item-container__inner {
      display: table-cell;
      vertical-align: middle;
    }
    .item {
      width: 100%; height: 600px;
    }
    .item_1 {
      background: url(../img/home_11.jpg) no-repeat center center / cover;
    }
    .item_2 {
      background: url(../img/home_12.jpg) no-repeat center center / cover;
    }
    .item_3 {
      background: url(../img/home_14.jpg) no-repeat center center / cover;
    }
  }
}
.shop-slideshow__heading {
  margin: 0 0 20px 0;
  font-family: 'Questrial', sans-serif;
  font-size: 46px;
  line-height: 1.2;
  color: white;
}
.shop-slideshow__subheading {
  margin-bottom: 30px;
  font-family: 'Questrial', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: white;
}
.shop-slideshow__btn {
  border: 2px solid white;
  border-radius: 0;
  color: white;
  font-weight: 600;

  &:hover, &:focus {
    color: @text-color;
    background-color: white;
  }
}
@media(max-width: @screen-xs-max) {
  .shop__slideshow {

    .carousel-inner {

      .item {
        height: 400px;
        padding: 0 30px;
        text-align: center;
      }
    }
  }
  .shop-slideshow__heading {
    font-size: 32px;
  }
  .shop-slideshow__subheading {
    font-size: 16px;
  }
}

/* Carousel controls */

.shop-slideshow__control {
  display: block;
  position: absolute;
  top: 50%; left: 10px;
  width: 30px; height: 70px;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity .3s;

  &:hover {
    opacity: 1 !important;
  }
}
.shop-slideshow__control[data-slide="next"] {
  left: auto; right: 10px;
}
.shop__slideshow:hover .shop-slideshow__control {
  opacity: .3;
}


/* Features */

.shop-index-features__item {
  margin-bottom: 40px;
  text-align: center;
}
.shop-index-features__icon {
  margin-bottom: 20px;
  width: 90px; height: 100px;
  background: url(../img/hexagon.svg) no-repeat center center / cover;
  .center-block();
  line-height: 100px;
  text-align: center;
  font-size: 24px;
}
.shop-index-features__heading {
  margin-bottom: 15px;

  + p {
    color: @text-muted;
  }
}

/* Blog post */

.shop-index-blog__posts > [class*="col-"] {
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: 1px solid fade(black,5%);

  &:last-child {
    border-right: 0;
  }
}
.shop-index-blog__post {
  width: 80%;
  .center-block();
}
.shop-index-blog__img {
  position: relative;
  float: left;
  margin-right: 30px;
  margin-bottom: 20px;
  width: 90px; height: 100px;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: url(../img/hexagon_reversed.svg) no-repeat top left / 100% 100%;
  }
  > img {
    height: 100%; width: auto;
  }
}
.shop-index-blog__body {
  overflow: hidden;
}
.shop-index-blog__heading {
  position: relative;
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 1.5;

  &:after {
    content: "";
    position: absolute;
    bottom: -15px; left: 0;
    width: 30px; height: 2px;
    background-color: fade(black,10%);
  }
}
.shop-index-blog__content {
  margin-bottom: 20px;
  color: @text-muted;
}
@media(max-width: @screen-sm-max) {

  .shop-index-blog__img {
    float: none;
    margin-right: 0;
    .center-block();
  }
  .shop-index-blog__heading {
    text-align: center;

    &:after {
      left: 50%;
      margin-left: -15px;
    }
  }
}
@media(max-width: @screen-xs-max) {
  .shop-index-blog__posts > [class*="col-"] {
    padding-top: 0;
    padding-bottom: 60px;
    border-right: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }
  .shop-index-blog__post {
    width: 100%;
  }
}


/* Newsletter */

.shop-index__newsletter {
  padding-bottom: 20px;

  .shop-index__heading {
    margin-bottom: 20px;
    line-height: 42px;
    text-align: center;
  }
  input[type="email"] {
    height: 42px;
    padding: 11px 12px;
  }
  button[type="submit"] {
    padding: 11px 30px;
    width: 100%;
  }
}
@media(min-width: @screen-sm-min) {

  .shop-index__newsletter {

    .shop-index__heading {
      margin-bottom: 0px;
      text-align: right;
    }
    input[type="email"] {
      border-radius: 21px 0 0 21px;
    }
    button[type="submit"] {
      margin-left: -3px;
      border-radius: 0 21px 21px 0;
      width: auto;
    }
  }
}


/** Shop: Thumbnails **/

.shop__thumb {
  border: 1px solid fade(black,5%);
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
  text-align: center;
  transition: border-color .1s, box-shadow .1s;

  &:hover {
    border-color: fade(black,7%);
    box-shadow: 0 5px 30px fade(black,7%);
  }
  > a {
    color: @text-color;

    &:hover {
      text-decoration: none;
    }
  }
}
.shop-thumb__img {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}
.shop-thumb__title {
  font-weight: 600;
}
.shop-thumb__price {
  color: @gray-light;
}
.shop-thumb-price_old {
  text-decoration: line-through;
}
.shop-thumb-price_new {
  color: red;
}


/** Shop: Item **/

.shop-item__info {
  padding: 30px;
  margin-bottom: 40px;
  background-color: white;
  border: 1px solid fade(black,5%);
}
.shop-item-cart__title {
  margin-bottom: 20px;
  line-height: 1.3;
}
.shop-item-cart__price {
  font-size: 28px;
  font-weight: 400;
  color: #F7C41F;
}
.shop-item__intro {
  color: @gray-light;
  border-bottom: 1px solid fade(black,5%);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.shop-item__add {

  button[type="submit"] {
    padding: 15px 20px;
  }
}
.shop-item__img {
  margin-bottom: 40px;
}
.shop-item-img__main {
  width: ~"calc(100% - 110px)"; height: auto;
  float: left;
}
.shop-item-img__aside {
  width: 100px; height: auto;
  float: left;

  > img {
    cursor: pointer;
    margin-bottom: 10px;
    border: 1px solid fade(black,10%);
    opacity: .5;

    &:hover, &.active {
      border-color: fade(black,5%);
      opacity: 1;
    }
  }
}
@media(max-width: @screen-xs-max) {
  .shop-item-img__main {
    width: ~"calc(100% - 60px)";
  }
  .shop-item-img__aside {
    width: 50px;
  }
}


/** Shop: Filter **/

.shop__filter {
  margin-bottom: 40px;
}

/* Shop filter: Pricing */

.shop-filter__price {
  padding: (@grid-gutter-width/2);

  [class*='col-'] {
    padding: 2px;
  }
}

/* Shop filter: Colors */

.shop-filter__color {
  display: inline-block;
  margin: 0 2px 2px 0;

  input[type="text"] {
    display: none;
  }
  label {
    width: 30px; height: 30px;
    background: transparent;
    border: 1px solid fade(black,10%);
    border-radius: 3px;
    cursor: pointer;
  }
}


/** Shop: Sorting **/

.shop__sorting {
  list-style: none;
  padding-left: 0;
  margin-bottom: 40px;
  margin-top: -20px;
  border-bottom: 1px solid fade(black,10%);
  text-align: right;

  > li {
    display: inline-block;

    > a {
      display: block;
      padding: 20px 10px;
      margin-bottom: -1px;
      border-bottom: 2px solid transparent;
      color: #757575;
      transition: all .05s linear;

      &:hover, &:focus {
        color: @text-color;
        text-decoration: none;
      }
    }

    &.active > a {
      color: @brand-primary;
      border-bottom-color: @brand-primary;
    }
  }
}
@media(max-width: @screen-xs-max) {
  .shop__sorting {
    text-align: left;
    border-bottom: 0;

    > li {
      display: block;

      > a {
        padding: 10px 15px;
        margin-bottom: 10px;
        border-bottom: 1px solid fade(black,10%);
      }
      &.active > a {
        font-weight: 600;
      }
    }
  }
}


/** Shop: Checkout **/

.checkout__block {
  margin-bottom: 40px;
}
.checkout-cart__item {
  margin-bottom: 15px;
}
.checkout-cart-item__img {
  max-width: 80px;
  margin-right: 10px;
  float: left;
}
.checkout-cart-item__content {
  overflow: hidden;
}
.checkout-cart-item__heading {
  margin-top: 0;
}
.checkout-cart-item__footer {
  padding: 10px 0;
  margin-top: 10px;
  border-top: 1px solid #eee;
}
.checkout-cart-item__price {
  font-weight: 600;
}
.checkout-total__block {
  margin-bottom: 40px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;

  > .row > [class*="col-"] {
    padding: 40px 40px;
    border-right: 1px solid #e9e9e9;
    color: #aaa;

    &:last-child {
      border-right: 0;
      color: @text-color;
    }
  }
}
@media(max-width: @screen-xs-max) {
  .checkout-total__block {
    border: 0;

    > .row > [class*="col-"] {
      padding: 15px 20px;
      border: 0;
      border-top: 1px solid #e9e9e9;

      &:last-child {
        border-bottom: 1px solid #e9e9e9;
      }
    }
  }
}
.checkout-total__heading {
  float: left;
}
.checkout-total__price {
  float: right;
  margin: 9px 0;
  font-size: 17px;
}
.checkout__submit {
  padding: 15px 40px;
}


/** Shop: Order Confirmation */

.shop-conf__heading {
  margin-bottom: 40px;

  + p {
    margin-bottom: 100px;
  }
}