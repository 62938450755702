/**
 * Panels
 */

@panel-inner-border: fade(black,5%);
@panel-default-border: fade(black,10%);

.panel-heading {
  text-transform: uppercase;
  border-color: @panel-inner-border;
}

#panel {

  .panel(@panel-color; @panel-font-color) {
    border-color: fade(black,10%);

    > .panel-heading {
      color: @panel-font-color;
      background-color: lighten(@panel-color, 5%);
      border: 1px solid @panel-default-border;
      border-radius: @border-radius-base @border-radius-base 0 0;
      margin: -1px -1px 0 -1px;
    }

    > .panel-heading + .panel-collapse .panel-body {
      border-top-color: fade(black,10%);
    }

    > .panel-footer + .panel-collapse .panel-body {
      border-bottom-color: fade(black,10%);
    }

    > .panel-footer {
      background-color: @panel-footer-bg;
      color: @text-color;
    }

  }
}

/* Panel Colors */

.panel-primary {
  #panel > .panel(@brand-primary; white);
}
.panel-secondary {
  #panel > .panel(@brand-sec; white);
}


/* Accordion */

.panel-group .panel-heading {
  position: relative;
  padding: 0;
}
.panel-group .panel-heading > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;

  &:after {
    position: absolute;
    right: 10px;
    height: 20px;
    width: 20px;
    content: "\f107";
    background: fade(black,15%);
    border-radius: 20px;
    border: 1px solid fade(black,5%);
    text-align: center;
    line-height: 20px;
    font-family: FontAwesome;
    color: white;       
  }
}
.panel-group .panel-heading > .collapsed:after {
  content: "\f105" !important;    
}

/* Panel body */

.panel-body {

  > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    > li {
      border-bottom: 1px solid @panel-inner-border;

      &:last-child {
        border-bottom: 0;
      }

      > a {
        display: block;
        padding: 8px 0;
        font-weight: 600;
        font-size: 11px;
        color: @gray-light;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          color: @text-color;
        }
      }

    }
  }
}