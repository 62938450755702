/**
 * Carousel
 */

/* Slideshow controls */

.carousel-arrow {
  position: absolute;
  top: 50%; left: 0; margin-top: -25px;
  display: block;
  width: 50px; height: 50px;
  text-align: center;
  border: 1px solid fade(black,12%);
  border-left-width: 0;
  border-radius: 0 @border-radius-base @border-radius-base 0;
  background-color: fade(white,70%);
  color: fade(black,70%);
  font-size: 28px;
  transition: all .1s linear;

  > .fa {
    line-height: 50px;
  }
  &:hover {
    padding-right: 5px;
  }
  &:hover, &:focus {
    color: fade(black,70%);
  }
}
.carousel-arrow-next {
  left: auto; right: 0;
  border-left-width: 1px;
  border-right-width: 0;
  border-radius: @border-radius-base 0 0 @border-radius-base;

  &:hover {
    padding-right: 0;
    padding-left: 5px;
  }
}
.carousel-arrow {
  opacity: 0;
}
.carousel:hover .carousel-arrow {
  opacity: 1;
}

/* Carousel indicators: Inverse */

.carousel-indicators_inverse > li {
  border-color: #ccc;

  &.active {
    width: 10px; height: 10px;
    margin: 0;
    border-color: #ccc;
    background-color: #ccc;
  }
}

/* Fade slide transition */

/*
  Bootstrap Carousel Fade Transition (for Bootstrap 3.3.x)
  CSS from:       http://codepen.io/transportedman/pen/NPWRGq
  and:            http://stackoverflow.com/questions/18548731/bootstrap-3-carousel-fading-to-new-slide-instead-of-sliding-to-new-slide
  Inspired from:  http://codepen.io/Rowno/pen/Afykb 
*/
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}