/**
 * Pagination
 */

@pagination-color: @text-color;
@pagination-border: fade(black,10%);
@pagination-hover-color: white;
@pagination-hover-bg: @brand-primary;
@pagination-hover-border: @pagination-border;
@pagination-active-color: white;
@pagination-active-border: @pagination-border;
@pagination-disabled-color: @text-color;
@pagination-disabled-border: @pagination-border;

/* Border radius */

.pagination,
.pagination-lg,
.pagination-sm {
  border-radius: @border-radius-base;

  > li:first-child > a,
  > li:first-child > span {
    border-top-left-radius: @border-radius-base;
    border-bottom-left-radius: @border-radius-base;
  }
  > li:last-child > a,
  > li:last-child > span {
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
  }
}

/* General */

.pagination {
  background-color: #f9f9f9;

  > li > a, > li > span {
    background: transparent;
    border-left-width: 0;
    box-shadow: 1px 0 1px fade(white,90%);
    transition: all .05s linear;
  }
  > li > a:hover, li > span:hover,
  > li > a:focus, li > span:focus,
  > li.active > a, li.active > span {
    box-shadow: inset 0 0 10px fade(black,20%);
  }
  > li:first-child > a,
  > li:first-child > span {
    border-left-width: 1px;
  }
  > li.disabled > a, li.disabled > span {
    border-left-width: 1px;

    &:hover, &:focus {
      box-shadow: none;
      z-index: 1;
    }
  }
}

/* Pager */

.pager > li > a,
.pager > li > span {
  background-color: #f9f9f9;
  color: @text-color;
  transition: all .05s linear;

  &:hover, &:focus {
    box-shadow: inset 0 0 10px fade(black,20%);
    background-image: none;
    .reset-filter();
    color: white;
  }
}
.pager > li.disabled > a, .pager > li.disabled > a:hover, .pager > li.disabled > a:focus,
.pager > li.disabled > span, .pager > li.disabled > span:hover, .pager > li.disabled > span:focus {
  background-image: none;
  .reset-filter();
  box-shadow: none;
}