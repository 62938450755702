/**
 * Comments
 */

.comments {
  margin: 20px 0;
}
.comments__heading {
  margin-bottom: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: @gray-light;
}

/* New comment */

form[name="comments__new"] {
  margin-bottom: 30px;
  .clearfix();

  textarea {
    height: @input-height-base;
  }
  button[type="submit"] {
    float: right;
  }
}

/* List of comments */

.comments__item {
  margin-bottom: 30px;
}
.comments-item__avatar {
  float: left;
  margin-right: 20px;
  width: 50px; height: 50px;
  border-radius: 50%;
  overflow: hidden;  
}
.comments-item__body {
  overflow: hidden;  
}
.comments-item__info {
  margin-bottom: 10px;
  .clearfix();
}
.comments-item-info__author,
.comments-item-info__divider,
.comments-item-info__timestamp {
  float: left;
}
.comments-item-info__author {
  font-weight: 600;
}
.comments-item-info__divider {
  margin-left: 15px;
  margin-right: 15px;
  line-height: @line-height-computed;
  color: #ddd;
  font-size: 6px;
}
.comment-item-info__timestamp {
  color: #999;
}
.comments-item__content {
  line-height: 1.7;
  color: @gray-light;
  margin-bottom: 10px;
}
.comments-item__actions {

  > a {
    color: #999;

    &:hover, &:focus {
      color: @text-color;
    }
  }
}