/**
 * Home App Landing
 */

.home-app__container {
  font-size: (@font-size-base + 1px);
  font-weight: 300;
  line-height: 1.7;
  
  /* General styles */

  > .section {
    margin-bottom: 0;

    .section__inner {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    /* Sections by Styles */

    &.section_inverse {
      position: relative;
      background-color: @brand-gray;

      &:before {
        content: "";
        position: absolute;
        top: 3%; right: 3%; bottom: 3%; left: 3%;
        background: url(../img/world-map.png) no-repeat center center / cover;
        opacity: .4;
      }
    }

    &.section_wood {
      position: relative;
      background: black url("../img/dark_wood.png");

      &:before {
        content: "";
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        #gradient > .radial(fade(white,10%); transparent);
      }
    }

    &.section_download {
    position: relative;
    background-image: url(../img/home_8.jpg);

      &::before {
        content: "";
        position: absolute;
        top: 0; left: 0; 
        width: 100%; height: 100%;
        background-color: fade(black,60%);
      }      
    }
  }

  /* First slide (home) */

  > .section:first-child {
    position: relative;
    background-image: url(../img/home_8.jpg);
    margin-top: -@line-height-computed;

    &::before {
      content: "";
      position: absolute;
      top: 0; left: 0; 
      width: 100%; height: 100%;
      background-color: fade(black,60%);
    }
    .home-app__heading {
      font-size: 48px;

      &:before {
        display: none;
      }
    }
  }
}
@media(min-width: @screen-sm-min) {
  
  /* Sections by Layout */

  .section_centered {

    .row {
      display: table;
      table-layout: fixed;
      width: 100%; height: 100%;

      [class*="col-"] {
        display: table-cell;
        vertical-align: middle;
        float: none;
        height: 100%;
      }
    }
  }
  .section > .section__inner {
    padding: 110px 0 !important;
  }
}

/* Home App: Typography */

.home-app__heading {
  position: relative;
  margin: 0 0 40px;
  font-family: 'Questrial', sans-serif;
  font-size: 42px;
  font-weight: 300;

  &:before {
    content: "";
    position: absolute;
    width: 30px; height: 2px;
    background-color: @brand-primary;
    bottom: -20px;
    left: 0;
  }
  &.text-center:before {
    left: 50%;
    margin-left: -15px;
  }
  &.inverse {
    text-shadow: 1px 1px 1px fade(black,20%);
    color: fade(white,90%);
  }
}
.home-app__subheading {
  margin: 0 0 40px;
  font-size: (@font-size-base + 2px);

  &.inverse {
    color: fade(white,60%);
  }
}
@media(min-width: @screen-sm-min) {
  .home-app__button {

    > .btn  {
      padding: 15px 25px;
    }
  }
}
@media(max-width: @screen-xs-max) {
  .home-app__heading {
    font-size: 32px !important;
    text-align: center;

    &:before {
      left: 50%;
      margin-left: -15px;
    }
  }
  .home-app__subheading {
    font-size: (@font-size-base + 1px) !important;
    text-align: center;
  }
  .home-app__button {
    text-align: center;
  }
}

/* Home App: Images */

.home-app__img {
  
  > img {
    max-width: 250px;
    margin: 0 auto;
  }
}
.home-app__img_center {
  max-width: 250px;
  margin: 0 auto;
}
.home-app__img_lg {

  > img {
    max-width: 350px;
  }
}

/* Home App: Features */

.home-app__features {
  margin: 20px 0;
}
.home-app__feature {
  margin-bottom: 40px;
  text-align: left;
}
.home-app-feature__icon {
  float: left;
  margin-left: auto;
  margin-right: 20px;
  height: 50px; width: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid fade(black,10%);
  border-radius: 50%;
  font-size: 15px;
  color: #757575;
}
.home-app-feature__body {
  overflow: hidden;
}
.home-app-feature__title {
  font-family: 'Questrial', sans-serif;
  margin: 0 0 10px 0;
  font-size: 20px;
  color: #000;
}
@media(min-width: @screen-sm-min) {
  .home-app__feature_left {
    text-align: right;

    .home-app-feature__icon {
      float: right;
      margin-left: 20px;
      margin-right: auto;
    }
  }
}

/* Home App: Describe */

.home-app__features-list {
  padding-left: 0;
  list-style: none;
  color: fade(white,60%);

  > li {
    margin-bottom: 15px;

    > .fa {
      font-size: 15px;
      color: @brand-primary;
      margin-right: 10px;
    }
  }
}
@media(min-width: @screen-sm-min) {
  .home-app__features-list {
    > li > .fa {
      margin-right: 20px;
    }
  }
}

/* Home App: Video */

.home-app__video {
  box-shadow: 0 10px 50px fade(black,50%);
}

/* Home App: Reviews */

.home-app__review {
  margin-bottom: 40px;
}
.home-app-review__avatar {
  width: 100px; height: 100px;
  border-radius: 50%;
  margin: 0 auto 20px;
  overflow: hidden;
}
.home-app-review__content {
  margin-bottom: 20px;
}
.home-app-review__body {
  text-align: center;
}
.home-app-review__author {

  > span {
    font-family: 'Questrial', sans-serif;
    font-size: 22px;
    color: #333;
  }
}
@media(min-width: @screen-sm-min) {
  .home-app__review {
    display: table;
  }
  .home-app-review__avatar {
    display: table-cell;
    vertical-align: middle;
    float: left;
    margin: 0 50px 0 0;
    width: 200px; height: 200px;
  }
  .home-app-review__body {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    text-align: left;
  }
}

/* Home App: Pricing */

.home-app__pricing {

  .row > div[class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}
.home-app-pricing__item {
  padding: 40px;
  margin-left: -1px;
  margin-top: -1px;
  border: 1px solid #ddd;
  text-align: center;
  transition: all .1s linear;

  &:hover {
    box-shadow: 0 15px 50px fade(black,30%);
  }
}
.home-app-pricing__title {
  font-family: 'Questrial', sans-serif;
  font-size: 22px;
  font-weight: 400;
}
.home-app-pricing__price {
  margin: 20px 0;
  font-size: 48px;
  color: @brand-primary;

  > span {
    font-size: (@font-size-base + 2px);

    &:first-child {
      vertical-align: 100%;
    }
  }
}
.home-app-pricing__content {
  margin-bottom: 10px;
  font-weight: 400;
}

/* Home App: Navigation */

#fp-nav {

  > ul > li > a > span,
  > ul > li > a > span:hover,
  > ul > li > a > span:focus {
    width: 12px; height: 12px;
    margin: -6px 0 0 -6px;
    padding: 2px;
    background-clip: content-box !important;
    background: transparent;
    border: 1px solid #ccc;
  }
  > ul > li > .active > span,
  > ul > li > .active > span:hover,
  > ul > li > .active > span:focus {
    background-color: #ccc;
  }
}

/* Animation */

[data-animation]:not(.animated) {
  visibility: hidden;
}