/**
 * Scrolltopcontrol
 */

.backtotop {
  background: fade(black,20%);
  padding: 10px 15px;
  font-size: 18px;
  color: #FFF;
  border-radius: @border-radius-base;
  box-shadow: inset 0 0 1px fade(black,20%);
  text-shadow: 1px 1px fade(black,20%);
  transition: background .1s;

  &:hover {
    background: fade(black,40%);
  }
}