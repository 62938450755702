/**
 * Scaffolding
 */

@body-bg: white;
@link-color: @brand-sec;
@link-hover-color: @brand-sec;

.text-primary {
  color: @brand-primary !important;
}
.text-secondary {
  color: @brand-sec !important;
}