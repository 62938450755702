/**
 * Lightbox
 */

.lightboxOverlay {
  filter:;
  opacity: 1;
  background-color: fade(black,50%);
}
.lightbox .lb-image,
.lb-outerContainer,
.lb-dataContainer {
  border-radius: 0;
}