/**
 * Tooltips
 */

@tooltip-bg: darken(black, 80%);
@tooltip-arrow-color: @tooltip-bg;

.tooltip-inner {
  padding: 7px;
  border-radius: 2px;
}