/**
 * Dropdowns styles
 */

@dropdown-border: fade(black,10%);
@dropdown-link-color: #858585;
@dropdown-link-hover-color: @text-color;
@dropdown-link-hover-bg: fade(black,2%);
@dropdown-link-active-color: @text-color;
@dropdown-link-active-bg: @dropdown-link-hover-bg;

.dropdown-menu {
  padding: 0;
  box-shadow: 0px 2px 10px 0 fade(black,10%);
  .border-top-radius(0);
}
.navbar-nav > li > .dropdown-menu > li > a,
.dropdown-submenu > .dropdown-menu > li > a {
  padding: 10px 15px 10px 30px !important;
  border-bottom: 1px solid fade(black,10%);
}

@media (min-width: @grid-float-breakpoint) {
  .navbar-nav > li > .dropdown-menu,
  .dropdown-submenu > .dropdown-menu {
    margin-top: 1px;

    &:before {
      content: "";
      position: absolute;
      top: -2px;
      height: 0; left: -1px; right: -1px;
      border-top: 2px solid @brand-sec;            
    }
  }
  .navbar-inverse .navbar-nav > li > .dropdown-menu,
  .navbar-inverse .dropdown-submenu > .dropdown-menu {

    &:before {
      left: 0; right: 0;
      border-top-color: fade(@brand-sec,75%);
    }    
  }
  .navbar-nav > li > .dropdown-menu > li > a,
  .dropdown-submenu > .dropdown-menu > li > a {
    padding: 8px 15px !important;
    border-bottom: 1px solid fade(black,5%);
  }
  .navbar-nav > li > .dropdown-menu > li:last-child > a,
  .dropdown-submenu > .dropdown-menu > li:last-child > a {
    border-bottom: 0;
    .border-bottom-radius(@border-radius-base);
  }
}

/*** Dropdown Sub Menu ***/

.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top:  -1px;
  left: 100%;
  margin: 0;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
@media (max-width: @grid-float-breakpoint-max) {
  .dropdown-submenu > .dropdown-menu {
    display: block;

    > li > a {
      padding-left: 45px !important;
      font-size: (@font-size-base - 1px);
    }
  }
}
@media(min-width: @grid-float-breakpoint) {
  .dropdown-submenu > a:after {
    position: absolute;
    right: 10px;
    content: "\f105";
    font-family: FontAwesome;
  }
}
.dropdown-submenu.pull-left {
  float:none !important;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left:  -100%;
  right: 100%;
}